export default {
  beforeRender: function() {
    this.style.display = `block`;
    this.style.height = `${this.attrs.height || 10}px`;
    this.style.width = `${this.attrs.width || 100}%`;
    this.templateStyle = `display: block; width: ${this.attrs["progress"]}%; height: 100%;`;
    this.templateStyle += `background-color: ${this.attrs.color || "#000"};`;
    this.template = `<go-progress-line style="${this.templateStyle}"></go-progress-line>`;
  },
  render: function() {
    this.innerHTML = this.template;
  },
};
